@font-face {
	font-family: "Montserrat-SemiBold";
	src: local("Montserrat-SemiBold"),
	url("./Fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Light";
	src: local("Montserrat-Light"),
	url("./Fonts/Montserrat-Light.ttf") format("truetype");
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

