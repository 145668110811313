
.Montserrat-SemiBold {
 font-family: "Montserrat-SemiBold";
}

.Montserrat-Light {
 font-family: "Montserrat-Light";
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat-Light";
}


.row>*, .container, .container-fluid, .row, .col-lg-6 {
  margin: 0;
  padding: 0;
}

.ax-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  outline: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.ax-button:focus {
  outline: none;
}

.a11y {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

.caps {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.white {
  color: #fff;
}

a {
  text-decoration: none;
  color: #000;
}

.orange {
  background-color: #FF7101;
  color: #fff;
}

.font_1 {
  font-size: 1.3em;
}

.main-image {
  position: relative;
  background: url('./assets/GettyImages-1044029414.jpg') no-repeat center;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

.container_layout {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.container_layout h1 {
  margin: 0;
  font-size: 97px;
  position: absolute;
  top: 47%;
  z-index: 99;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-family: "Montserrat-SemiBold";
  letter-spacing: 1px;
}

label {
  z-index: 3 !important;
  color: #fff;
}

.overlay {
  background-color: rgba(0,0,0,.7);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
  padding: 0 !important;
  margin: 0 !important;
}

.logo {
  position: absolute;
  top: 8%;
  left: 6%;
  width: 265px;
  height: 65px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
}

.lang_btns {
  position: absolute;
  top: 10%;
  right: 6%;
  z-index: 3;
}

input:checked + label {
  border-bottom: 2px solid #FF7101;
}

.border_h1 {
  border-bottom: 5px solid #FF7101;
  position: relative;
  height: 10px;
  padding-bottom: 13px;
}

.desc_title {
  padding: 0 !important;
  font-size: 2.13em;
  width: 80%;
  text-align: left;
  line-height: 1.4;
  white-space: pre-line;  
  vertical-align: bottom;
  white-space: pre-line;  
  vertical-align: bottom;
  font-family: "Montserrat-SemiBold";
}

.layout_container {
  padding: 40px 0;
  height: 100%;
}

.border-right {
  border-right: 1px solid #ccc;
}

.horizon_container {
  width: 100%;
  position: relative;
  /*background-color: blue;*/
  box-sizing: border-box;
}

.visit_container {
  margin-left: 28%;
  width: 72%;
  padding-top: 2.3%; /*patch*/
}

.visit_container_2 {
  margin-left: 20%;
  width: 80%;
}

.sponsor_icon {
  height: 37%;
  width: 63%;
  margin: 0 18.5% 0 18.5%;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(.7);
  /*background-color: red;*/
  padding-top: 8%;

}

.sponsor_icon_2 {
  height: 60%;
  width: 80%;
  margin: 0 8.5%;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(.7);
  /*background-color: red;*/
}

.footer {
  background-color: #000;
  color: #fff;
  font-size: .75em;
}

.footer_container {
  width: 35%;
  margin-left: 5%;
}

footer a {
  color: #fff !important;
}

.meta_container_title {
  width: 70%;
  margin: 0 15%;
  text-align: left;
}

.meta_container_desc {
  width: 70%;
  margin: 0 15%;
  text-align: left;
  font-size: 1.17em;
}

.fa-chevron-right {
  color: #444444;
  font-weight: normal;
  font-size: .7em;
}

@media only screen and (min-width: 916px) and (max-width: 1024px) {
 
 .container_layout h1 {
    font-size: 64px;
    top: 47%;
  }

  .desc_title {
    width: 100%;
    text-align: center;
    line-height: 1.2;
    font-size: 42px;
  }

  .meta_container_desc {
    width: 90%;
    margin: 0 5%;
    /*font-size: 32px;*/
    font-size: 1.7em;
  }
}

@media only screen and (min-width: 568px) and (max-width: 915px) {

  .main-image {
    height: 70vh;
  }

  .container_layout h1 {
    font-size: 42px;
    top: 47%;
  }

  .logo {
    width: 190px;
    height: 55px;
    top: 5%;
  }

  .lang_btns {
    top: 6.5%;
  }

  .desc_title {
    width: 100%;
    text-align: center;
    line-height: 1.2;
    font-size: 32px
  }

  .sponsor_icon, .sponsor_icon_2 {
    width: 90%;
    margin: 0 5%;
  }

  .visit_container, .visit_container_2 {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .border-right {
    border: none;
  }

  .meta_container_desc {
    text-align: center;
    font-size: 24px;
  }


  .footer_container {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-left: 0;
    flex-direction: column;
  }

  .hide_on_mobile {
    display: none;
  }
}


@media only screen and (min-width: 375px) and (max-width: 567px) {
  .main-image {
    height: 40vh;
  }

  .container_layout h1 {
    font-size: 28px;
    top: 47%;
  }

  .logo {
    width: 190px;
    height: 55px;
    top: 5%;
  }

  .lang_btns {
    top: 6.5%;
  }

  .meta_container_title, .meta_container_desc {
    text-align: center;
    width: 90%;
    margin: 0 5%;
  }

  .desc_title {
    width: 100%;
    text-align: center;
    line-height: 1.2;
    font-size: 1.5em; 
  }

  .sponsor_icon, .sponsor_icon_2 {
    width: 90%;
    margin: 0 5%;
  }

  .visit_container, .visit_container_2 {
     width: 100%;
     margin: 0;
    text-align: center;
    }

  .border-right {
    border: none;
  }

  .footer_container {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-left: 0;
    flex-direction: column;
  }

  .hide_on_mobile {
    display: none;
  }
}

@media only screen and (max-width: 374px) {
  .main-image {
    height: 40vh;
  }

  .container_layout h1 {
    font-size: 18px;
    top: 47%;
  }

  .logo {
    width: 150px;
    height: 40px;
    top: 5%;
  }

  .lang_btns {
    top: 6.5%;
  }

  .meta_container_title, .meta_container_desc {
    text-align: center;
    width: 90%;
    margin: 0 5%;
  }

  .desc_title {
    width: 100%;
    text-align: center;
    line-height: 1.2;
    font-size: 16px; 
  }

  .meta_container_desc {
    font-size: 14px;
  }

  .sponsor_icon, .sponsor_icon_2 {
    width: 90%;
    margin: 0 5%;
  }

  .visit_container, .visit_container_2 {
     width: 100%;
     margin: 0;
    text-align: center;
    }

  .border-right {
    border: none;
  }

  .footer_container {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-left: 0;
    flex-direction: column;
  }

  .hide_on_mobile {
    display: none;
  }
}




/*


@media only screen and (min-width: 912px) and (max-width: 1024px) {
  .main-image {
    height: 70vh;
  }

  .container_layout h1 {
    font-size: 64px;
  }

  .meta_container_title {
    width: 100%;
    margin: 0 0;
    text-align: center;
  }

  .desc_title {
    text-align: center;
    width: 100%;
  }

  .footer_container {
    width: 50%;
  }
}

@media only screen and (min-width: 844px) and (max-width: 911px) {

  .main-image {
    height: 85vh;
  }

  .container_layout h1 {
    font-size: 42px;
  }


  .meta_container_title {
    width: 100%;
    margin: 0 0;
    text-align: center;
  }

  .desc_title {
    text-align: center;
    width: 100%;
    font-size: 32px;
  }

  .meta_container_desc  {
    text-align: center;
    font-size: 24px;
  }


  .footer_container {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 843px) {
  .main-image {
    height: 60vh;
  }

  .container_layout h1 {
    font-size: 44px;
  }

  .meta_container_title {
    width: 100%;
    margin: 0 0;
    text-align: center;
  }

  .desc_title {
    text-align: center;
    width: 100%;
  }

  .meta_container_desc  {
    text-align: center;
  }

  .footer_container {
    width: 50%;
  }
}

@media only screen and (min-width: 740px) and (max-width: 767px) {
  .main-image {
    height: 90vh;
  }

  .container_layout h1 {
    font-size: 44px;
  }

  .meta_container_title {
    width: 100%;
    margin: 0 0;
    text-align: center;
  }

  .desc_title {
    text-align: center;
    width: 100%;
  }

  .meta_container_desc  {
    text-align: center;
  }

  .footer_container {
    width: 50%;
  }
}


@media only screen and (min-width: 415px) and (max-width: 739px) {

  .main-image {
    height: 70vh;
  }

  .container_layout h1 {
    font-size: 42px;
    top: 50%;
  }

  .meta_container_title, .meta_container_desc {
    text-align: center;
    width: 90%;
    margin: 0 5%;
  }

  .desc_title {
    width: 100%;
    text-align: center;
    font-size: 32px;
  }

  .footer_container {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-left: 0;
    flex-direction: column;
  }

  .meta_container_desc  {
    font-size: 24px;
  }

  .hide_on_mobile {
    display: none;
  }
  
}

@media only screen and (min-width: 281px) and (max-width: 414px) {

  .main-image {
    height: 40vh;
  }

  .container_layout h1 {
    font-size: 28px;
    top: 47%;
  }

  .logo {
    width: 190px;
    height: 55px;
    top: 5%;
  }

  .lang_btns {
    top: 6.5%;
  }

  .meta_container_title, .meta_container_desc {
    text-align: center;
    width: 90%;
    margin: 0 5%;
  }

  .desc_title {
    width: 100%;
    text-align: center;
    line-height: 1.2;
    font-size: 1.5em; 
  }

  .sponsor_icon, .sponsor_icon_2 {
    width: 90%;
    margin: 0 5%;
  }

  .visit_container, .visit_container_2 {
     width: 100%;
     margin: 0;
    text-align: center;
    }

  .border-right {
    border: none;
  }

  .footer_container {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-left: 0;
    flex-direction: column;
  }

  .hide_on_mobile {
    display: none;
  }
  
}

*/